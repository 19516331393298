<template>
  <CRow class="languages-summary">
    <CCol col="12">
      <CCard class="zq--wizard-card">
        <CCardHeader @click="collapse">
          <div class="d-flex justify-content-between">
            <strong class="title">{{ texts.title }}</strong>
            <CLink class="card-header-action btn-minimize">
              <ClCardArrow :is-collapsed="basicIsCollapsed" />
            </CLink>
          </div>
        </CCardHeader>
        <CCollapse :show="basicIsCollapsed" :duration="400">
          <CCardBody>
            <!-- TABS -->
            <CRow class="mb-4">
              <CCol col="12" class="d-flex flex-wrap align-items-center">
                <ul class="translation-tabs">
                  <li v-for="(lang, name, index) in langData" :key="index" class="translation-tab-item">
                    <button
                      class="translation-tab-button"
                      :class="{'translation-tab-button__active': currentLang !== name}"
                      @click="handleTranslation(name)"
                    >
                      <span
                        v-if="translatableFieldsCount !== 0 && (Object.values(langData[name]).filter(item => item !== null).length === translatableFieldsCount)"
                        :class="{'check-icon__active': currentLang !== name}"
                        class='exclaimIcon check-icon'
                      >
                        <i class="fa fa-check"></i>
                      </span>
                      <span
                        v-else-if="Object.values(langData[name]).filter(item => item !== null).length < translatableFieldsCount && Object.values(langData[name]).filter(item => item !== null).length > 0"
                        :class="{'exclaimPartialCompleteIcon__active': currentLang !== name}"
                        class='exclaimPartialCompleteIcon'
                      >
                        <i class="fa fa-exclamation"></i>
                      </span>
                      <span
                        v-else
                        :class="{'exclaim__active': currentLang !== name}"
                        class="exclaimIcon"
                      >
                        <i class="fa fa-exclamation"></i>
                      </span>
                      <span
                        class="translation-tab-lang"
                        :class="{'translation-tab-lang__active': currentLang !== name}"
                      >
                        {{ name }}
                      </span>
                    </button>
                  </li>
                </ul>
              </CCol>
            </CRow>
            <!-- Fields -->
            <div v-if="currentLang">
              <div v-for="(val, key, index) in langData[currentLang]" :key="index">
                <h6 class="mb-2">{{ key }}</h6>
                <CRow class="mb-4" v-if="!editorFields.includes(key)">
                  <CCol col="12" md="5" class="text-left">
                    <CInput
                      v-model="entityData[key]"
                      add-input-classes="col-sm-12"
                      disabled="true"
                    />
                  </CCol>
                  <CCol class="d-flex flex-column align-items-center text-center justify-content-center mb-2">
                    <CIcon name="cil-arrow-right" class='check-icon' />
                    <span>  to {{ currentLang }}</span>
                  </CCol>
                  <CCol col="12" md="5">
<!--                    <CInput-->
<!--                      :value="langData[currentLang][key] || ''"-->
<!--                      add-input-classes="col-sm-12"-->
<!--                      placeholder="Insert text here..."-->
<!--                      :disabled="true"-->
<!--                    />-->
                    <ClEditor
                      :value-prop="langData[currentLang][key] || ''"
                      :disabled="true"
                      :style="{maxHeight: '40px', overflow: 'hidden'}"
                    />
                  </CCol>
                </CRow>
                <CRow class="mb-4" v-else>
                  <CCol col="12" md="5">
                    <ClEditor
                      :disabled="true"
                      :value-prop="entityData[key]"
                    />
                  </CCol>
                  <CCol class="d-flex flex-column align-items-center text-center mb-2">
                    <CIcon name="cil-arrow-right" class='check-icon' />
                    <span>  to {{ currentLang }}</span>
                  </CCol>
                  <CCol col="12" md="5">
                    <ClEditor
                      :value-prop="langData[currentLang][key] || ''"
                      :disabled="true"
                    />
                  </CCol>
                </CRow>
              </div>
            </div>
          </CCardBody>
        </CCollapse>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { translationsViewTransform } from '@/utils/translationsUtils';
import ClCardArrow from '@/shared/UI/ClCardArrow';
import ClEditor from '@/shared/components/formComponents/ClEditor';
import { translationsTexts } from '@/config/sharedTexts/translations.json';
import { mapActions } from 'vuex';
import { cloneDeep } from 'lodash';
import { pageConfig } from '@/config';

export default {
  name: 'ViewTranslations',
  components: {
    ClCardArrow,
    ClEditor
  },
  props: {
    entityId: {
      type: String,
      default: '',
    },
    entityData: Object,
    translatableFields: {
      type: Array,
      default: () => [],
    },
    createTranslationsData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      texts: {
        ...translationsTexts
      },
      editorFields: ['description', 'termsAndConditions'],
      basicIsCollapsed: false,
      currentLang: "",
      langData: {},
      updateName: [],
      translatableFieldsCount: 0,
      sortBy: pageConfig.sortBy,
      page: pageConfig.page,
    }
  },
  mounted() {
    this.initialize();
  },
  methods: {
    ...mapActions('translations', ['handleGetTranslationsByEntityId']),
    ...mapActions('languages', ['handleGetLanguagesByQuery']),
    handleTranslation(lang) {
      this.currentLang = lang;
    },
    async initialize() {
      this.translatableFieldsCount = this.translatableFields.length
    },
    async collapse() {
      this.basicIsCollapsed = !this.basicIsCollapsed;
      if (this.createTranslationsData && Object.keys(this.createTranslationsData).length) {
        this.langData = cloneDeep(this.createTranslationsData);
        this.currentLang = Object.keys(this.langData)[0];
      }
      if (this.basicIsCollapsed && Object.keys(this.langData).length === 0) {
        let data = await this.handleGetTranslationsByEntityId({refIdsArray: [this.entityId], limit: 99, skip: 0});
        const languages = await this.handleGetLanguagesByQuery({
          queryRequest: {
            sortBy: this.sortBy,
            limit: 1000,
            skip: 0
          }
        });
        // this.handelUpdateLang(data)
        if (data.length) {
          this.langData = translationsViewTransform(data, languages);

          if (Object.keys(this.langData).length) {
            this.currentLang = Object.keys(this.langData)[0];
          }
        }
      }
    },
    /*handelUpdateLang(data) {
      if (this.updateIds.length > 0 || this.createIds.length > 0) {
        this.updateIds.forEach(id => {
          data.forEach(item => {
            if (id === item.id) {
              this.updateName.push(item.languageKey)
            }
          })
        });
        this.createIds.forEach(id => {
          data.forEach(item => {
            if (id === item.id) {
              this.updateName.push(item.languageKey)
            }
          })
        });
      }
    },*/
    isUpdateName(tabName) {
      let result = false;
      this.updateName.forEach(name => {
       if (name === tabName) {
         result = true;
       }
      });
      return result;
    }
  }
}
</script>

<style lang="scss">
.languages-summary {
  .zq--editor.zq--editor--disabled {
    background-color: #f3f3f3;
  }
}

.exclaimIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  line-height: 16px;
  margin-right: 0.3em;
  background: var(--zq-el-grey-bg);
  color: white;
}

.exclaimPartialCompleteIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  line-height: 16px;
  margin-right: 0.3em;
  background: var(--zq-el-pink-bg);
  color: white;
  &__active {
    opacity: 0.5;
  }
}

.c-nxt-app__main-theme .translation-tab-button .check-icon {
  background: var(--zq-el-green-bg);
  &__active {
    background: lighten(#3bb54c, 15%);
  }
}
</style>
